var scrollToAnchorOffSet = 120;

jQuery.event.special.touchstart = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
    }
  };
 
$(".nav__button").click(function () {
    var parent = $(this).parent();
    var navItem = parent.parent();
    if (navItem.hasClass("active")) {
        navItem.removeClass("active");
    }
    else {
        navItem.addClass("active");
    }
   
});
$(function () {

    // Add class if edge
    if (/Edge/.test(navigator.userAgent)) {
        $('body').addClass('ie ie--edge');
    }

    // Add class if IE 11
    var is_ie11 = navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1;
    if (is_ie11) {
        $('body').addClass('ie ie--11');
    }

    // Add class if IE10
    var is_ie10 = navigator.appVersion.indexOf("MSIE 10") !== -1;
    if (is_ie10) {
        $('body').addClass('ie ie--10');
    }

    // Add class if safari
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (is_safari) {
        $('body').addClass('body--safari');
    }

    // Remove empty paragraphs
    $('p').filter(function(){ return $.trim(this.innerHTML)==="&nbsp;" }).remove();


    // /* === Add aria labels to owl carousel buttons === */
    // $('.owl-carousel').each(function() {
    //     //Find each set of dots in this carousel
    //     $(this).find('.owl-dot').each(function(index) {
    //         //Add one to index so it starts from 1
    //         $(this).attr('aria-label', index + 1);
    //     });
    // });


    // /* === Custom bullet list === */
    // $('.li--tickbox').closest('ul').addClass('ul--stripped');


});


function scrollToAnchor(aName) {
    var aTag = $("a[name='" + aName + "']");
    $('html,body').animate({ scrollTop: aTag.offset().top }, 'slow');
}
function scrollToAnchorID(aId) {
    var aTag = $("#" + aId);
    $('html,body').animate({ scrollTop: aTag.offset().top }, 'slow');
}

function scrollToAnchorOffset(aName) {
    var aTag = $("a[name='" + aName + "']");
    $('html,body').animate({ scrollTop: aTag.offset().top - scrollToAnchorOffSet }, 'slow');
}
function scrollToAnchorIDOffset(aId) {
    var aTag = $("#" + aId);
    $('html,body').animate({ scrollTop: aTag.offset().top - scrollToAnchorOffSet }, 'slow');
}

// function owl_Carousel_Init(element, childElements, autoSlide_user, autoplayTimeout_user, slideSpeedTransition) {

//     if (element === null || typeof element === 'undefined' || element === '') {
//         alert('You need to specify an element for the owl carousel slider to attach to');
//     }
//     if (childElements === null || typeof childElements === 'undefined' || childElements === '') {
//         alert('You need to specify the slide elements for the owl carousel slider to attach to');
//     }
//     if (autoplayTimeout_user === null || typeof autoplayTimeout_user === 'undefined' || autoplayTimeout_user === '') {
//         autoplayTimeout_user = 5000;
//     }
//     if (slideSpeedTransition === null || typeof slideSpeedTransition === 'undefined' || slideSpeedTransition === '') {
//         slideSpeedTransition = 1000;
//     }


//     var bannerSlide = $(element);

//     bannerSlide.each(function (i) {
//         $(this).attr('id', i + 1);
//         var item_count = parseInt($(this).find(childElements).length);  // '.main-banner__slide'
//         var true_false = false;
//         if (item_count > 1) {
//             true_false = true;
//         } else {
//             true_false = false;
//         }
//         var autoSlide = true_false;

//         if (true_false === true) {
//             // Only do this check if true. Don't want to be setting auto scroll if only one element
//             autoSlide = autoSlide_user;
//         } else {
//             autoSlide = false;
//         }

//         var owl = $(this).owlCarousel({
//             items: 1,
//             margin: 0,
//             loop: true,
//             dots: true,
//             nav: false,
//             touchDrag: true_false,
//             mouseDrag: true_false,
//             lazyLoad: true,
//             autoplay: autoSlide,
//             autoplayTimeout: autoplayTimeout_user,


//             navSpeed: slideSpeedTransition, // One of these has to work?
//             smartSpeed: slideSpeedTransition,
//             autoplaySpeed: slideSpeedTransition,
//             fluidSpeed: slideSpeedTransition
//         });

//         owl.on('changed.owl.carousel', function (e) {
//             if (autoSlide) {
//                 owl.trigger('stop.owl.autoplay');
//                 owl.trigger('play.owl.autoplay');
//             }

//             // Stop any playing videos
//             $('.video-container iframe').each(function () {
//                 var el_src = $(this).attr("src");
//                 $(this).attr("src", el_src);
//             });

//         });
//     });
// }


// Used on the hospital pages

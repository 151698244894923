$(function () {
    var search_filters = $('.search__filters');
    var search_background = $('.search__filter-background');
    var search_toggle = $('.search__filter-trigger');

    search_filters.click(function(e){
        e.stopPropagation();
    });

    $('.search__aggregation-title').click(function(){
        $(this).closest('.search__aggregation').toggleClass('search__aggregation--collapse');
    });

    $('.search__aggregation-icon').click(function(){
        $(this).toggleClass('search__aggregation-icon--active');
    });

    search_toggle.click(function(e){
        e.stopImmediatePropagation();
        search_filters.addClass('search__filters--show');
        search_background.addClass('search__filter-background--show');
    });

    $('.search__filters-title').click(function(){
        search_filters.removeClass('search__filters--show');
        search_background.removeClass('search__filter-background--show');
    });

    $('.search__filters-apply').click(function(){
        search_filters.removeClass('search__filters--show');
        search_background.removeClass('search__filter-background--show');
        $("body,html").animate({
            scrollTop: $("#search__results").offset().top - 90
        }, 800);
    });

    search_background.click(function(){
        search_filters.removeClass('search__filters--show');
        search_background.removeClass('search__filter-background--show');
    });
});
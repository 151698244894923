var searchHandleBarsTemplate = null;
var itemsPerPage = 30;
var SearchType = "";
var initialSearch = true;
var searchParams = null;

function initHandleBarsHelpers() {
    Handlebars.registerHelper("formatDate", function (datetime, format) {
        if (moment) {
            //format = DateFormats[format] || format;
            return moment(datetime).format(format);
        } else {
            return datetime;
        }
    });

    Handlebars.registerHelper('if_eq', function (a, b, opts) {
        if (a === b) {
            return opts.fn(this);
        } else {
            return opts.inverse(this);
        }
    });
}

// Init - Called from the script Utils.AddScript() tag in the template
function initSiteSearchHandleBars(hasSearchTerm, _itemsPerPage) {
    $('#search__results').html("<p><i>Loading..</i></p>");
    if (_itemsPerPage !== undefined && _itemsPerPage !== null && parseInt(_itemsPerPage) > 0) {
        itemsPerPage = parseInt(_itemsPerPage);
    }

    if (hasSearchTerm === true) {
        initialSearch = false;  // Prevent a keyword from the query string from being overwritten
    }

    SearchType = "SiteSearch";
    initHandleBarsHelpers();

    // Init handlebars and get the results
    var source = document.getElementById("searchTemplate").innerHTML;
    searchHandleBarsTemplate = Handlebars.compile(source);
    var prom = GetSearchResults(1);
    prom.then(
        function (result) {
            console.log("Search results : %o", result);

            var html = searchHandleBarsTemplate(result);
            $('#search__results').html(html);

            $('#pagination_search').pagination({
                items: result.totalResultCount,
                itemsOnPage: itemsPerPage,
                cssStyle: 'light-theme',
                onPageClick: function (pageNumber, event) {
                    DoAjaxSearch(pageNumber);
                    // scrollToAnchorIDOffset('topOfSearch'); ('topOfSearch');
                    document.getElementById("topOfSearch").scrollIntoView();
                },
                onInit: function () {
                    var resultCount = 0;
                    if (result.totalResultCount !== null) {
                        resultCount = result.totalResultCount;
                    }
                    doPagination(resultCount, 1);
                }
            });
        }
    );


    $('#searchboxSite').keypress(function (event) {
        var keycode = event.keyCode ? event.keyCode : event.which;
        if (keycode === '13') {
            DoAjaxSearch(1);
        }
    });

    // Add events for checkboxes
    $(".search__aggregation input[type='checkbox']").click(function (e) {
        // show active filter area
        var checkbox = e.target;

        if (checkbox.checked) {
            // show active filters
            document.getElementById("activeFilters").classList.remove("hidden");

            // add item to active filters
            var labelText = checkbox.parentElement.textContent;
            var li = document.createElement("li");
            li.setAttribute("data-filter-id", checkbox.id);
            var activeFilterItem = document.createElement("button");
            activeFilterItem.textContent = labelText;
            activeFilterItem.classList.add("tag", "tag--filter", "button", "button--text");
            activeFilterItem.title = "Remove filter";
            var icon = document.createElement("i");
            icon.classList.add("icon", "icon--close");
            icon.setAttribute("aria-hidden", "true");
            activeFilterItem.appendChild(icon);
            li.appendChild(activeFilterItem);
            document.getElementById("activeFilterList").appendChild(li);

            li.addEventListener("click", function () {
                // uncheck filter checkbox
                checkbox.checked = false;
                li.remove();
                if (document.querySelectorAll(".filterSearch:checked").length === 0) {
                    // hide active filters
                    document.getElementById("activeFilters").classList.add("hidden");
                }
                DoAjaxSearch(1);
            }.bind(this));
        } else {
            // remove item from active filters
            document.getElementById("activeFilterList").querySelector("[data-filter-id='" + checkbox.id + "'").remove();

            if (document.querySelectorAll(".filterSearch:checked").length === 0) {
                // hide active filters
                document.getElementById("activeFilters").classList.add("hidden");
            }
        }

        DoAjaxSearch(1);
    });

    document.getElementById("searchboxSite").addEventListener("search", function (event) {
        DoAjaxSearch(1);
    });

}

function changeFilterText() {
    var filters = $(".search__aggregation input[type='checkbox']:checked");
    if (filters.length > 0) {
        $("#clearNewsFilters").css("display", "block");
        $("#noFiltersSelected").css("display", "none");
    }
    else {
        $("#clearNewsFilters").css("display", "none");
        $("#noFiltersSelected").css("display", "block");
    }
}

function clearPublicationSearch() {
    $('.search__aggregation input[type=checkbox]').prop('checked', '');

    var datepicker = $('#dateRangePicker').datepicker().data('datepicker');

    datepicker.clear();
    $('#startDate').val('');
    $('#endDate').val('');

    DoAjaxSearch(1);
}
function clearNewsSearch() {
    //  var datepicker = $('#dateRangePicker').datepicker().data('datepicker');
    //  datepicker.clear();
    //  $('#startDate').val('');
    //  $('#endDate').val('');

    // $('#searchboxSite').val('');

    $('.search__aggregation input[type=checkbox]').prop('checked', '');
    $('.search__aggregation-icon').removeClass('search__aggregation-icon--active');

    DoAjaxSearch(1);
}
function clearSiteSearch() {
    // remove all items from active filter list
    document.getElementById("activeFilterList").innerHTML = "";

    // Uncheck all the boxes
    $('.search__aggregation input[type=checkbox]').prop('checked', '');
    $('.search__aggregation-icon').removeClass('search__aggregation-icon--active');

    // Recheck the Content type boxes
    $('.search__aggregation input[type=checkbox][data-group="ContentType"]').prop('checked', 'checked');


    // hide active filters
    document.getElementById("activeFilters").classList.add("hidden");


    DoAjaxSearch(1);
}

// The following groups are called by convention using searchType  e.g. 'populate' + SearchType + '()'
function populatePublicationSearch(fieldsObj) {
    console.log('Populate form from cookie object : %o', fieldsObj);
    $('#searchboxSite').val(fieldsObj.keywords);

    if (typeof fieldsObj["startDate"] !== 'undefined' && typeof fieldsObj["endDate"] !== undefined) {
        var datepicker = $('#dateRangePicker').datepicker().data('datepicker');
        var dates = [moment(fieldsObj.startDate).toDate(), moment(fieldsObj.endDate).toDate()];
        //console.log(dates);
        datepicker.selectDate(dates);
    }

    checkBoxesByValue(fieldsObj.publicationType);
}
function populateNewsSearch(fieldsObj) {
    console.log('Populate form from cookie object : %o', fieldsObj);
    $('#searchboxSite').val(fieldsObj.keywords);

    if (typeof fieldsObj["startDate"] !== 'undefined' && typeof fieldsObj["endDate"] !== undefined) {
        var datepicker = $('#dateRangePicker').datepicker().data('datepicker');
        var dates = [moment(fieldsObj.startDate).toDate(), moment(fieldsObj.endDate).toDate()];
        //console.log(dates);
        datepicker.selectDate(dates);
    }
}
function populateSiteSearch(fieldsObj) {
    console.log('Populate form from cookie object : %o', fieldsObj);
    $('#searchboxSite').val(fieldsObj.keywords);

    // Checked by default so in this case we need to disable all then apply the saved ones
    $('input[type=checkbox][data-group="ContentType"]').prop('checked', '');
    checkBoxesByValue(fieldsObj.contentType);

    checkBoxesByValue(fieldsObj.dataType);
    //checkBoxesByValue(fieldsObj.health);
    //checkBoxesByValue(fieldsObj.services);
}

// Takes an array of values and checks the corresponding boxes
function checkBoxesByValue(arrayOfValues) {
    if (typeof arrayOfValues !== 'undefined') {
        if (arrayOfValues.length > 0) {
            for (var i = 0; i < arrayOfValues.length; i++) {
                $('input[type=checkbox][value="' + arrayOfValues[i] + '"]').prop('checked', 'checked');
            }
        }
    }
}
// Some boxes are ticked by default and we need to untick
function uncheckBoxesByValue(arrayOfValues) {
    if (typeof arrayOfValues !== 'undefined') {
        if (arrayOfValues.length > 0) {
            for (var i = 0; i < arrayOfValues.length; i++) {
                $('input[type=checkbox][value="' + arrayOfValues[i] + '"]').prop('checked', 'checked');
            }
        }
    }
}

function doPagination(resultCount, currentPage) {
    var $pagination = $('#pagination_search');
    console.log('Page results count : %o - Per page : %o', resultCount, itemsPerPage);

    if (resultCount !== null && resultCount > itemsPerPage) {
        $pagination.pagination('destroy');
        $pagination.pagination('updateItems', resultCount);
        $pagination.pagination('drawPage', currentPage);
        $pagination.show();
    } else {
        $pagination.hide();
    }
}

function DoAjaxSearch(pageNo) {
    var prom = GetSearchResults(pageNo);
    prom.then(
        function (result) {
            console.log("Processing ajax results : %o", result);

            var html = searchHandleBarsTemplate(result);
            $('#search__results').html(html);

            doPagination(result.totalResultCount, pageNo);

            // Order by drop down
            var selector = '#orderField option[value=' + searchParams.orderField + ']';
            $(selector).attr('selected', 'selected');

            // scrollToAnchorIDOffset('topOfSearch');
            document.getElementById("topOfSearch").scrollIntoView();
        }
    );
}

// Do our ajax call to fetch the results
function GetSearchResults(pageNo, rootNode) {
    return new Promise(function (resolve, reject) {
        //var searchParams = null;
        var paramSource = "";

        // If we have a cookie use that object to search and populate the form
        if (initialSearch === "disabled" && Cookies.get(SearchType)) {
            // Get from cookie
            paramSource = "cookie";
            searchParams = JSON.parse(Cookies.get(SearchType));
            // default
            searchParams.rootNodeId = rootNode;
            var fn = window['populate' + SearchType];
            if (typeof fn === "function") {
                fn(searchParams);
            } else {
                console.error('Missing populate function %o so can\'t populate form', 'populate' + SearchType);
            }
        } else {
            // Default
            paramSource = "fields";
            searchParams = buildSiteSearchParams(pageNo);
        }

        console.log('GetSearchResults from %o searchParams : %o', paramSource, searchParams);
        initialSearch = false;
        $.ajax({
            url: "/Umbraco/Surface/SiteSearch/DoSiteSearchJSON",
            method: 'post',
            data: searchParams
        }).done(function (result) {
            //Used to display message that search was modified due to no results - clear on load and only display if additional keyword searched and found results - handled in ExamineSiteSearch
            document.getElementById("showAlternative").innerText = " ";
            $('#search-autocomplete').attr('placeholder', result.initialKeywordUsed);


            if (result.additionalKeywordUsed != null) {
                //Open the dropdown
                if (result.taxonomyParent != null) {
                    var trimmedParentString = result.taxonomyParent.replace(/\s/g, '');
                    $('#' + trimmedParentString).removeClass("search__aggregation--collapse");
                }

                var trimmedString = result.additionalKeywordUsed.replace(/\s/g, '');
                //Check the box containing the searched taxonomy item
                $('#' + trimmedString).attr('checked', 'checked')
                document.getElementById("additionalSearchKeyword").value = result.additionalKeywordUsed;


                if (result.additionalKeywordUsed !== result.initialKeywordUsed) {
                    var c1Value = "We don't have any exact results for " + result.initialKeywordUsed + ", but here are some results for " + result.additionalKeywordUsed;
                    document.getElementById("showAlternative").innerText = c1Value;
                }
            }
            resolve(result);
        }).fail(function (result) {
            console.log('Failed', result);
            reject(result);
        });
    });
}

function filterToArray(collection) {
    var result = new Array();
    var length = collection.length;

    for (var j = 0; j < length; j++) {
        result.push($(collection[j]).val());
    }

    return result;
}

function isEmptyOrSpaces(str) {
    if (str === undefined) { return true; }
    return str === null || str.match(/^ *$/) !== null;
}

function buildSiteSearchParams(pageNo) {
    searchParams = {};
    searchParams.resultPage = pageNo;

    // Keywords
    if ($('#searchboxSite').val() !== '') {
        searchParams.keywords = $('#searchboxSite').val();
    }
    // additional keywords to stop searching within searches on changing facets
    if ($('#additionalSearchKeyword').val() !== '') {
        searchParams.additionalSearchKeyword = $('#additionalSearchKeyword').val();
    }
    // Specific doc type(s) - Can be used to create a news or event only listing
    if ($('#searchSpecificDocType').val() !== '') {
        searchParams.searchSpecificDocType = $('#searchSpecificDocType').val();
    }

    // content type : "content" or "media"  - (empty = both)
    if ($('#searchContentType').val() !== null && $('#searchContentType').val() !== '') {
        searchParams.contentTypeCorM = $('#searchContentType').val();
    }

    // Root node restriction . e.g. publications only look in the archive folder
    var rnv = $('#rootNode').val();
    if (rnv !== null && rnv !== '' && rnv !== undefined) {
        searchParams.rootNodeId = $('#rootNode').val();
    }

    // Date filter target field
    if ($('#dateFilterField').val() !== null && $('#dateFilterField').val() !== '') {
        searchParams.dateFilterField = $('#dateFilterField').val();
    }

    // Results order
    if ($('#orderField').val() !== null && $('#orderField').val() !== '') {
        searchParams.orderField = $('#orderField').val();
    }

    // Results per page
    if ($('#resultsPerPage').val() !== null && $('#resultsPerPage').val() !== '') {
        searchParams.resultsPerPage = $('#resultsPerPage').val();
    }

    /// =================================
    /// Custom taxonomy
    /// =================================


    //Regions Taxonomy
    var checks1 = $("input[type='checkbox']:checked[data-group='taggingRegions']");
    if (checks1.length) {
        searchParams.taggingRegions = filterToArray(checks1);
    }
    //Markets Taxonomy
    var checks2 = $("input[type='checkbox']:checked[data-group='taggingMarkets']");
    if (checks2.length) {
        searchParams.taggingMarkets = filterToArray(checks2);
    }
    //ContentTypes Taxonomy
    var checks3 = $("input[type='checkbox']:checked[data-group='taggingContentType']");
    if (checks3.length) {
        searchParams.taggingContentType = filterToArray(checks3);
    }
    //Topic Taxonomy
    var checks4 = $("input[type='checkbox']:checked[data-group='taggingTopic']");
    if (checks4.length) {
        searchParams.taggingTopic = filterToArray(checks4);
    }
    //END OF TAXONOMY


    if (!isEmptyOrSpaces($('#startDate').val()) && !isEmptyOrSpaces($('#endDate').val())) {
        searchParams.startDate = $('#startDate').val();
        searchParams.endDate = $('#endDate').val();
    }

    // Save a cookie
    if (SearchType === "") {
        console.log('Search type not set so no cookie name available');
    } else {
        console.log('Setting %o cookie to %o', SearchType, searchParams);
        Cookies.set(SearchType, searchParams, {
            //expires: 1 - Session cookie
        });
    }

    return searchParams;
}

var searchHandleBarsTemplate = null;

// Init
// ==========================

function initDetails(serviceType, serviceId) {
    console.log("Init details");

    getServiceDetails(serviceType, serviceId).then(function (result) {
        UpdateServicePageWithLiveDetails(result);
    }).catch(function () {
        //alert('Failed to get data from NDS');
        $('#serviceResults').find('.loading').removeClass('hidden').html('Sorry, Failed to get latest results. Cached version shown below.');
        $('.opening-times-loading').text('Failed to get opening times. Please try again later');
    });
}

// API Calls
// ==========================

function getServiceDetails(serviceType, serviceId) {
    return new Promise(function (resolve, reject) {
        var sData = {
            serviceType: serviceType,
            serviceId: serviceId
        }

        $.ajax({
            url: "/umbraco/surface/NSD/GetServiceDetails",
            method: 'post',
            data: sData
        }).done(function (result) {
            console.log('Success : %o', result);
            resolve(result);
        }).fail(function (result) {
            console.log('Failed');
            reject(result);
        });
    });
}

// Helpers
// ==========================

function UpdateServicePageWithLiveDetails(sd) {
    console.log("Lucy test");
    var serviceWrapper = $('#serviceResults');

    serviceWrapper.find('.loading').addClass('hidden');

    console.log("SUMMARY: %o", sd.Description);
    // Summary
    if (!isEmptyOrSpaces(sd.Description)) {
        serviceWrapper.find('.summary').html(sd.Description).removeClass('hidden');
    }

    // Service list
    if (sd.Service.length > 0) {
        var items = "<ul>";
        $.each(sd.Service, function (index, value) {
            items += '<li>' + value.ServiceSubType.Text + '</li>'
            console.log("Value: %o", value.ServiceSubType.Text);
        });
        items += "</ul>";

        serviceWrapper.find('.servicesTitle').removeClass('hidden');

        serviceWrapper.find('.servicesList').removeClass('hidden').html(items);
    }

    // Address
    var add = sd.Contact;
    if (!isEmptyOrSpaces(add.Address1)) {
        serviceWrapper.find('.address1').html(sd.Address1).removeClass('hidden');
    }
    if (!isEmptyOrSpaces(add.Address2)) {
        serviceWrapper.find('.address2').html(sd.Address2).removeClass('hidden');
    }
    if (!isEmptyOrSpaces(add.Address3)) {
        serviceWrapper.find('.address3').html(sd.Address3).removeClass('hidden');
    }
    if (!isEmptyOrSpaces(add.Town)) {
        serviceWrapper.find('.addressTown').html(sd.Town).removeClass('hidden');
    }
    if (!isEmptyOrSpaces(add.AddrPostcodeess1)) {
        serviceWrapper.find('.addressPostcode').html(sd.Postcode).removeClass('hidden');
    }

    // Phone
    var phones = add.Phone;
    var primary = phoneLink(phones.Primary);
    var secondary = phoneLink(phones.Secondary);
    var tertiary = phoneLink(phones.Tertiary);

    if (primary.Missing === false) {
        var pPhone = serviceWrapper.find('.PrimaryPhone').removeClass('hidden');
        pPhone.find('label').text(primary.Description);
        pPhone.find('span').html(primary.Number);
    }
    if (secondary.Missing === false) {
        var pPhone = serviceWrapper.find('.SecondaryPhone').removeClass('hidden');
        pPhone.find('label').text(secondary.Description);
        pPhone.find('span').html(secondary.Number);
    }
    if (tertiary.Missing === false) {
        var pPhone = serviceWrapper.find('.TertiaryPhone').removeClass('hidden');
        pPhone.find('label').text(tertiary.Description);
        pPhone.find('span').html(tertiary.Number);
    }

    // Website
    var web = add.ExternalUrl;
    if (web !== null) {
        if (!isEmptyOrSpaces(web.Uri)) {
            serviceWrapper.find('.externalUrl a').attr('href', web.Uri).removeClass('hidden');
        }
    }

    // Opening times
    var ot = sd.Schedule.DailySchedule;
    if (ot !== null) {
        var times = serviceWrapper.find('.opening-times')

        $.each(ot, function (index, value) {
            var thisDay = times.find('.' + value.DayOfWeek);
            thisDay.find('strong').text(value.DayOfWeek);
            thisDay.find('.open').text(formatTime(value.Open));
            thisDay.find('.close').text(formatTime(value.Close));
        });
    }
    serviceWrapper.find('.opening-times-loading').addClass('hidden');
    serviceWrapper.find('.opening-times').removeClass('hidden');

    // Other
    if (!isEmptyOrSpaces(sd.ScheduleInformation)) {
        serviceWrapper.find('.ScheduleInformation').html(sd.ScheduleInformation).removeClass('hidden');
    }
    if (sd.Accessibility.length > 0) {
        var acc = "";
        $.each(sd.Accessibility, function (index, value) {
            acc += value.Text + "<br />";
        });

        serviceWrapper.find('.accessibility').removeClass('hidden');
        serviceWrapper.find('.accessibilityItems').html(acc).removeClass('hidden');
    }
}

function formatTime(time) {
    time = time.substring(0, 5);
    timeBits = time.split(':');
    timeHour = parseInt(timeBits[0]);

    // Get rid of the first digit if it's a 0
    //if (time.substring(0, 1) === "0") {
    //    time = time.substring(1, 5)
    //}

    if (timeHour < 12) {
        suffix = "am";
    } else {
        timeHour = timeHour - 12;
        time = timeHour + ':' + timeBits[1]
        suffix = "pm";
    }
    return (time + suffix);
}

function phoneLink(pDetails) {
    var p = {};
    if (pDetails != null) {
        p.Description = pDetails.Description;
        if (pDetails.Description == 'Fax') {
            p.Number = pDetails.Number;
        } else {
            p.Number = '<a href="tel:' + pDetails.Number + '">' + pDetails.Number + '</a>';
        }
        p.Missing = false;
        return p;
    } else {
        p.Missing = true;
        return p;
    }
}

function isEmptyOrSpaces(str) {
    return str === null || $.trim(str).match(/^ *$/) !== null;
}
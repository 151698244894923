// Announcement 

function initAnnouncement() {
    var CookieName = "announcement";
    $('#announcement__close').click(function () {

        //var date = $('#createdDate').val();
        var date2 = new Date();
        console.log(moment(date2).format('YYYY-MM-DD , h:mm:ss a '));

        $(".announcement").addClass('announcement--hide');

        //Need to specify PM 
        Cookies.set(CookieName, { createdDate: moment(date2, 'YYYY-MM-DD ,h:mm:ss A').format('YYYY-MM-DD , h:mm:ss a') }, {
            expires: 365,
        });

    });
};

function deferAnnouncement(method) {
    if (window.jQuery) {
        //console.log('All loaded and ready for announcement cookies');
        method();
    }
    else {
        setTimeout(function () { defer(method) }, 50);
    }
}

deferAnnouncement(function () {
    initAnnouncement();
}); 
var searchHandleBarsTemplate = null;
var findNearestSearchType = "findYourNearest"; 
var usingCurrentLocText = "Using Current Location";
var searchParams = {};


$(function () {

    //console.log('Init find nearest');

    // Handles the find nearest form submission
    $("#find-nearest__submit").click(function (event) {
        //console.log("#find-nearest__submit");

        event.preventDefault();
        console.log("Called from FYN Submit click");
        findNearestSubmit();
    });

    $("#find-nearest__submit__post").click(function (event) {
        //console.log("#find-nearest__submit__post");

        event.preventDefault();
        console.log("Called from FYN Submit POST");
        findNearestSubmitPost();
    }); 

    // Handles the current location button click
    $(".find-nearest__location").click(function (event) {

        event.preventDefault();

        var service = $("#chooseservice").val();

        $("#localSearchPostcode").prop('placeholder','Fetching location...');
        console.log('Started get geo : %o', new Date().getTime() / 1000);
        

        if (navigator.geolocation) {
             
            var location = navigator.geolocation.getCurrentPosition(
                function (position) {

                    $("#localSearchPostcode").val(usingCurrentLocText);

                    console.log('Got results get geo : %o', new Date().getTime() / 1000);

                    var coords = position.coords;
                    var lat = coords.latitude;
                    var long = coords.longitude;

                    
                    $("#localSearchPostcode").addClass('form-group__input--inactive');
                    $("#currentLat").val(lat);
                    $("#currentLong").val(long);

                    console.log("Called from find nearest location click");
                    buildServiceSearchParams('$(".find-nearest__location").click()');

                    // Do we do an in place search or a post. Check the form id

                    if (service !== "empty") {
                        if ($('#find-nearest__submit__post').length === 1) {
                            findNearestSubmitPost();
                        } else {
                            findNearestSubmit();
                        }
                        
                    }

                }, function (error) {

                    console.log('Geo error : %o', error);
                    $("#localSearchPostcode").prop('placeholder', 'Postcode');
                    $('.find-nearest__error').css('display', 'inline-block').html('<p>Unable to get location : ' + error.message + "</p><p>Please enter your postcode manually.</p>");
                    
                });
        }
  
    });

    // if_eq
    initHandleBarsHelpers();
});

function findNearestSubmitPost() {

    //event.preventDefault();
    var validation = validateForm();
    if (validation) {
        console.log("Called from find nearest submit post");
        buildServiceSearchParams('findNearestSubmitPost');
        $('#find-nearest-form').submit();
        $('html, body').animate({
            scrollTop: $('#nearestSearchResults').offset().top
        }, 'slow');
        var elmntToView = document.getElementById("nearestSearchResults");
        elmntToView.scrollIntoView();
       
        return;
    }
}

//function initMap() {
//    console.log("init map!");
//    const myLatLng = { lat: 55.8591, lng: -4.2581 };
//    const map = new google.maps.Map(document.getElementById("nearestServicesMap"), {
//        zoom: 8,
//        center: myLatLng,
//    });

//    new google.maps.Marker({
//        position: myLatLng,
//        map,
//        title: "Hello World!",
//    });
//}

/*window.initMap = initMap;*/

function findNearestSubmit() {
    $('#nearestSearchResults').html('Searching...');
    var service = $("#chooseservice").val();
    var postcode = $("#localSearchPostcode").val();
    var text = "";
    //event.preventDefault();
    var validation = validateForm();
    if (validation && service != "default") {
        var prom = fetchSearchResults(true);
        var html = "";
        prom.then(
            function (result) {
                processResults(result);
            }
        );
       
    } else {
        $('.find-nearest__error').css('display', 'inline-block').html('<p>Please enter your location and service type.</p>');
        if (service == "empty" && postcode == "") { text = '<p style="color: red;">Please select a location and a service to search.</p><br />'; }
        else if (service == "empty") {
            text = '<p style="color: red;">Please select a service to search.</p><br />';
        }
        else if (postcode == "") {
            text = '<p style="color: red;">Please select a location to search.</p><br />';
        }
        $('#nearestSearchResults').html("");
        $('#errorMessage').html(text);
    }
}



function validateForm() {
    var postcode = $("#localSearchPostcode").val();
    var service = $("#chooseservice").val();
    var passed = true;
    var error = "";

    if (postcode === "") {
        passed = false;
    }
    if (service === "empty") {
        passed = false;
    }

    
    if (passed) {
        $(".find-nearest__error").removeClass('find-nearest__error--show');
        $(".find-nearest__error").html("");
    } else {
        $(".find-nearest__error").addClass('find-nearest__error--show');
        $(".find-nearest__error").html("<p>Please fill in the postcode and service fields.</p>");
    }

    return passed;
}

function processResults(result) {

    if (result.Status === "Error" || result.length < 1) {

        $('.loading-overlay').addClass('loading-overlay--hide');
        html = "<p> Sorry, no results were found. </p>";
        if (result.Details !== null && result.Details !== undefined && result.Details !== '') {
            html = "<p style='color:red;'>Error -  " + result.Details + "</p>";
        }
        $('#nearestSearchResults').html(html); 
    }
    else {
        console.log("Service nearest search results : %o", result);
 
        var resultCollection = {
            results: result,
            cookie: searchParams
        };
         
        console.log('Obj to handlebars : %o', resultCollection);

       var html = searchHandleBarsTemplate(resultCollection);
        $('#nearestSearchResults').html(html);
      
        //   initAccordions();
       // initMap();

        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            $('html, body').animate({
                scrollTop: $('#nearestSearchResults').offset().top
            }, 'smooth');
        }
       
        const myLatLng = { lat: 55.8591, lng: -4.2581 };
        const map = new google.maps.Map(document.getElementById("nearestServicesMap"), {
            zoom: 8,
            center: myLatLng,
        });
        var bounds = new google.maps.LatLngBounds();
        resultCollection.results.forEach(element => {
            var location = element.Location.split(",");
            var latlng = { lat: parseFloat(location[0]), lng: parseFloat(location[1]) };
        
        var marker = new google.maps.Marker({
                position: latlng,
                map,
                title: element.ServiceName,
        });
            console.log("Details: %o", element);
            var name = element.ServiceName != null ? element.ServiceName + "<br>" : "";
            var add1 = element.Address1 != null ? element.Address1 + "<br>" : "";
            var add2 = element.Address2 != null ? element.Address2 + "<br>" : "";
            var add3 = element.Address3 != null ? element.Address3 + "<br>" : "";
            var postcode = element.Postcode != null ? element.Postcode + "<br>" : "";
            var phone = element.PhoneNumber != null || element.PhoneNumber != "" ? element.PhoneNumber + "<br>" : "";
            var detailsLink = element.Url != null ? "<a class='find-nearest__map-link' href='" + element.Url + "' >View Details</a>"  : "";

           
            var detailString = name + add1 + add2 + add3 + postcode + phone + detailsLink;
            var infoWindow = new google.maps.InfoWindow({
                content: detailString
            });
     
            google.maps.event.addListener(marker, 'click', function () {
                  infoWindow.open(map, this);
            });
            bounds.extend(marker.position);
        });
        map.fitBounds(bounds);

        setTimeout(function () {
            $('.loading-overlay').addClass('loading-overlay--hide');
        }, 500);
    }

}

function initLocalSearch() { 

    console.log('Raw cookie params : %o', Cookies.get(findNearestSearchType));
   
    searchParams = {};
    if (typeof Cookies.get(findNearestSearchType) !== 'undefined') {
        searchParams = JSON.parse(Cookies.get(findNearestSearchType));
    }
    //console.log('initLocalSearch params : %o', searchParams);

    var source = document.getElementById("nearestSearchTemplate").innerHTML;
    searchHandleBarsTemplate = Handlebars.compile(source);

    if (typeof searchParams.postcode !== 'undefined') {

        $('.content-area').addClass('loading-overlay');
        //console.log("Cookie exists");

        //console.log('initLocalSearch fetchSearchResults : %o', searchParams);
        var prom = fetchSearchResults();
        var html = "";
        prom.then(
            
            function (result) {
                processResults(result);
             }

        );
    } else {

        //console.log('Show empty search template : %o', searchParams);

        // Show missing parameters
        var empty = document.getElementById("emptySearchTemplate").innerHTML;
       var emptyTemplate = Handlebars.compile(empty);
        html = emptyTemplate(null);
        $('#nearestSearchResults').html(html);
    }
}

function fetchSearchResults(refresh) {
    //console.log("Fetching..");
    return new Promise(function (resolve, reject) {
        console.log("Called from fetchSearchResults");
        if (typeof Cookies.get(findNearestSearchType) !== 'undefined' && refresh != true) {
            searchParams = JSON.parse(Cookies.get(findNearestSearchType));
        }
        else {
            searchParams = buildServiceSearchParams('fetchSearchResults');
        }
       
        //console.log("fetchSearchResults search params: %o", searchParams);
        if (searchParams.postcode === usingCurrentLocText) {
            searchParams.postcode = "Current";
        }

        var data = { serviceType: searchParams.service, postcode: searchParams.postcode, recordCount: searchParams.recordCount };

        if (!(typeof searchParams.currentLat === 'undefined' || searchParams.currentLat === '' || searchParams.currentLat === 'NaN')) {
            // Only if it exists
            data.latitude = parseFloat(searchParams.currentLat).toFixed(6);
            data.longitude = parseFloat(searchParams.currentLong).toFixed(6);
        }

        console.log('Passing to GetNearestService controller : %o', data);
         
        $.ajax({
            url: "/Umbraco/Surface/NSD/GetNearestService",
            method: 'post',
            data: jQuery.param(data) 
        }).done(function (result) {
            console.log('done : %o', result);
            //if (result.Status === "Error") {
            //    reject(result);
            //} else {
                resolve(result);
            //}
        }).fail(function (result) {
            console.log('Failed : %o', result);
            reject(result);
        });
    });
}


function buildServiceSearchParams(calledFrom) {
    console.log("in build params");
    searchParams = {};    
    if ($('#localSearchPostcode').val() !== '') {

        searchParams.postcode = $('#localSearchPostcode').val();
    }
    if ($('#chooseservice').val() !== '') {
        searchParams.service = $('#chooseservice').val();
        searchParams.servicePretty = $('#chooseservice option:selected').attr('data-service');
    }
    if ($('#recordCount').val() !== '') {
        searchParams.recordCount = $('#recordCount').val();
   
    }
    if (searchParams.postcode === usingCurrentLocText) {
        if ($('#currentLat').val() !== '') {
            searchParams.currentLat = $('#currentLat').val();
        }
        if ($('#currentLong').val() !== '') {
            searchParams.currentLong = $('#currentLong').val();
        }
    } else {
        // Not a curretn search. Clear it out
        console.log('Clearing lat long');
        $('#currentLat').val('');
        $('#currentLong').val(''); 
    }

    console.log(calledFrom + ' is setting Find your nearest cookie to %o', searchParams);
    Cookies.set(findNearestSearchType, searchParams, {
        //expires: 1 - Session cookie
    });
    return searchParams; 
}